
/**
 * Created by wangtong on 2022/6/12
 */
import {Component, Vue, Prop, Watch} from "vue-property-decorator";
import {PropType} from 'vue'
import {createUserShareData, GroupBattleShareData, GroupCampShareData, GroupData, UserShareData} from "@/models";
import {UserApi} from "@/api/user";
import UserDivisionAvatarView from "@/components/user/UserDivisionAvatarView.vue";
import * as R from "@/config/R"
import {GroupBattleStageCons, UserDivisionLevelCons } from "@/constants";
import OssImageView from "@/components/basic/OssImageView.vue";
import {Browser, Convert} from "@sandan/febasic";
const icSplash = require("@/assets/images/ic_splash.png")

@Component({
    components: {OssImageView, UserDivisionAvatarView}
})
export default class GroupBattleShareCard extends Vue {
    @Prop() shareId!: any
    @Prop() shareData!: GroupBattleShareData

    get shareLink(): string {
        return `https://sandanapp.com/share/group-battle/${this.shareId}`
    }

    icSplash = icSplash

    get groupData(): GroupData {
        return this.shareData.invite_data.group
    }

    get divisionLogo(): string {
        return R.userDivisionLogoImage(this.shareData.invite_data.group.info.division)
    }

    get containerStyle(): any {
        return {
            'background-image': R.userDivisionBackgroundGradient(this.shareData.invite_data.group.info.division)
        }
    }

    onCopy() {
        Browser.copyToClipboard(Convert.parseString(this.shareData.record.text))
    }

    get isFinished(): boolean {
        return this.groupData.info.battle_stage == GroupBattleStageCons.Settle.value || this.groupData.info.battle_stage == GroupBattleStageCons.Finish.value
    }

    icVS = R.icVS
}
